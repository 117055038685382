import React, { useState, useEffect } from 'react';
import { Link ,useHistory  } from 'react-router-dom';
import Loading from '../../../common/Loading';
import axios from 'axios';
import { toast } from "react-toastify";
import * as path from "../../../../data/ApiList";  


const AddWebhook = (props) => { 
    const [reciveStatus, setReciveStatus] = useState(true);
    const [agent_id, setAgent_id] = useState();
    const [thirdparty_id, setThirdparty_id] = useState();
    const [password, setPassword] = useState('');
    const [webhook_url, setWebhook_url] = useState(path.REX_WEBHOOK_URL);
    const [email, setEmail] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const [trigger1_option, setTrigger1_option] = useState();
	const history  = useHistory();
    useEffect(() => {
		setAgent_id(Number(props.match.params.againtId));
        setTrigger1_option(props.match.params.trigger1_option);
		setThirdparty_id(Number(props.match.params.id))
        setTimeout(() => {
            setReciveStatus(false);
        }, 1000);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'webhook_url':
                setWebhook_url(value);
                break;
            case 'password':
                setPassword(value);
                break;
            default:
                break;
        }
    };
	const isValidEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};
    const handleSave = async () => {
        const params = { agent_id,trigger1_option, thirdparty_id, password, email, webhook_url }; 
		
        const newWebhookUrlErrors = {};
        if (!trigger1_option) newWebhookUrlErrors.trigger1_option = "Trigger option is required";
        if (!agent_id) newWebhookUrlErrors.agent_id = "Agent is required";
        if (!thirdparty_id) newWebhookUrlErrors.thirdparty_id = "API Endpoint is required";
        if (!password) newWebhookUrlErrors.password = "API Key is required";
		if (!email) {
			newWebhookUrlErrors.email = "Email is required";
		} else if (!isValidEmail(email)) {
			newWebhookUrlErrors.email = "Email is not valid";
		}
        if (!webhook_url) newWebhookUrlErrors.webhook_url = "Webhook URL is required";

        if (Object.keys(newWebhookUrlErrors).length) {
            setErrorMessages(newWebhookUrlErrors);
            return;
        }

        try {           
            const response = await axios.post(`${path.GATEWAY_URL}user/addwebhook`, params);
           // console.log("Webhook added successfully:", response.data);
			toast.success("Webhook added successfully");
			history.goBack();
        } catch (error) {
            //console.error("Error adding webhook:", );
			toast.warn(error.response.data?.message);
        }
    };

    return (
        <>
            {reciveStatus && <Loading />}
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Add Webhook</li>
                </ol>
            </nav>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <h5 className="hed01">Add Webhook</h5>
                    </div>
                    <div className="row el_gr">
                        <div className="col-sm-6 col-lg-4">
                            <div className="el">
                                <label>Email*</label>
                                <input className="form-control"
                                    name="email" value={email}
                                    onChange={handleInputChange}
                                />
                                {errorMessages.email && <div className="errorMsg">{errorMessages.email}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="el">
                                <label>Password *</label>
                                <input className="form-control" type="password"
                                    name="password" value={password}
                                    onChange={handleInputChange}
                                />
                                {errorMessages.password && <div className="errorMsg">{errorMessages.password}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <div className="el">
                                <label>Webhook Url *</label>
                                <input className="form-control" type='url'
                                    name="webhook_url" value={webhook_url} readOnly
                                    onChange={handleInputChange}
                                />
                                {errorMessages.webhook_url && <div className="errorMsg">{errorMessages.webhook_url}</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="down_pan">
                <div className="text-right">
                    <Link to="/admin/listthirdparty"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
                    <button className="btn_cf scss" onClick={handleSave}>
                        <i className="fal fa-save"></i>&nbsp;Save
                    </button>
                </div>
            </div>
        </>
    );
}

export default AddWebhook;
