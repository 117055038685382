// Live
export const GATEWAY_URL = "https://integrations.realflowfinance.com.au:5002/api/";
export const REX_WEBHOOK_URL = 'https://webhook.realflowfinance.com.au/webhook/vaultre/rex_webhook.php';
export const GATEWAY_URL_HOPMEPAGE = "https://integrations.realflowfinance.com.au/";

// Stagging
//export const GATEWAY_URL = "https://campaignflow.broadwayinfotech.net.au:5001/api/";
//export const REX_WEBHOOK_URL = 'https://campaignflowconnector.broadwayinfotech.net.au/webhook/vaultre/rex_webhook.php';
//export const GATEWAY_URL_HOPMEPAGE = "https://campaignflow.broadwayinfotech.net.au/";

// Local
//export const GATEWAY_URL = "http://localhost:5000/api/";
//export const REX_WEBHOOK_URL = "https://campaignflowconnector.broadwayinfotech.net.au/webhook/vaultre/rex_webhook.php";
//export const GATEWAY_URL_HOPMEPAGE = "http://localhost:3000/";


export const LOGIN_URL = `${GATEWAY_URL}user/login`;
export const LOGOUT_URL = "";
export const AGENTS_ADD_URL =  `${GATEWAY_URL}user/addagent`;
export const AGENTS_LIST_URL =  `${GATEWAY_URL}user/agentlist?keyword=`;
export const AGENTS_UPDATE_URL =  `${GATEWAY_URL}user/agentupdate`;
export const AGENTS_UPDATE_GET_URL =  `${GATEWAY_URL}user/editagent`;
export const AGENTS_DELETE_URL =  `${GATEWAY_URL}user/agentdelete`;


///Species Api list Started addagent//// agentlist 
// third party crm addthirdparty 
export const AGENTS_DROP_DAWN =  `${GATEWAY_URL}user/agents`;
export const THIRD_ADD_URL =  `${GATEWAY_URL}user/addthirdparty`;
export const THIRD_LIST_URL =  `${GATEWAY_URL}user/thirdpartycrmlist?keyword=`;
export const THIRD_PARTY_UPDATE_URL =  `${GATEWAY_URL}user/thirdpartycrmupdate`;
export const THIRD_PARTY_UPDATE_GET_URL =  `${GATEWAY_URL}user/editthirtpartcrm`;
export const THIRD_PARTY_DELETE_URL =  `${GATEWAY_URL}user/thirdpartydelete`;

//Internal list is started
export const INTERNAL_LIST_URL =  `${GATEWAY_URL}user/internalcrmlist?keyword=`;
export const INTERNAL_ADD_URL =  `${GATEWAY_URL}user/addinternalcrm`;
export const INTERNAL_UPDATE_URL =  `${GATEWAY_URL}user/internalcrmupdate`;
export const INTERNAL_UPDATE_GET_URL =  `${GATEWAY_URL}user/editinternalcrm`;
export const INTERNAL_DELETE_URL =  `${GATEWAY_URL}user/internalcrmdelete`;

//CRM drop dawn

export const CRM_DROP_DAWN_EXTERNAL_URL =  `${GATEWAY_URL}user/crmdropdown/external`;
export const CRM_DROP_DAWN_INTERNAL_URL =  `${GATEWAY_URL}user/crmdropdown/internal`;

//API Connector list is started
export const CONNECTOR_LIST_URL =  `${GATEWAY_URL}user/apiconnectorlist?keyword=`;
export const CONNECTOR_ADD_URL =  `${GATEWAY_URL}user/addapiconnector`;
export const CONNECTOR_UPDATE_URL =  `${GATEWAY_URL}user/apiconnectorupdate`;
export const CONNECTOR_UPDATE_GET_URL =  `${GATEWAY_URL}user/editapiconnector`;
export const CONNECTOR_GET_ALL_DROPDOWN_URL =  `${GATEWAY_URL}user/allconnectordrowdown/`;
export const CONNECTOR_DELETE_URL =  `${GATEWAY_URL}user/apiconnectordelete/`;
export const CONNECTOR_STATUS_UPDATE_URL =  `${GATEWAY_URL}user/connectorstatusupdate/`;

//API History Log
export const HISTORY_LOG_API =  `${GATEWAY_URL}user/propertyprocessloglist?keyword=`;
export const HISTORY_LOG_DETAILS_API =  `${GATEWAY_URL}user/propertyprocesslogdetails/`;
export const CONNECTOR_DROP_DAWN_URL =  `${GATEWAY_URL}user/apiconnectorlist?keyword=`;

//API Dricte consumer 
export const CONSUMER_ADD_URL =  `${GATEWAY_URL}user/adddirectconnector`;
export const CONSUMER_LIST_URL =  `${GATEWAY_URL}user/direct_connectors_list?keyword=`;
export const CONSUMER_UPDATE_GET_URL =  `${GATEWAY_URL}user/editdirectconnector`;
export const CONSUMER_UPDATE_URL =  `${GATEWAY_URL}user/updatedirectconnector`;
export const CONSUMER_STATUS_UPDATE_URL =  `${GATEWAY_URL}user/directconnectorstatusupdate/`;
export const GET_CONSUMER_DETAIL_URL =  `${GATEWAY_URL}user/consumerverification/`;
export const ADD_DIRECT_CONSUMER_DETAIL_URL =  `${GATEWAY_URL}user/senddirecttocampaignflow`;
export const CONSUMER_DELETE_URL =  `${GATEWAY_URL}user/deletedirectconnector/`;
export const CONSUMER_IMAGE_LOGO_DELETE_URL =  `${GATEWAY_URL}user/deleteconsumerimage/`;


//Log Consumer

export const CONSUMER_DROP_DAWN_URL =  `${GATEWAY_URL}user/direct_connectors_list?keyword=`;
export const HISTORY_LOG_DETAILS_CONSUMER_API =  `${GATEWAY_URL}user/directpropertyloglist?keyword=`;
export const HISTORY_LOG_DETAILS_API_CONSUMER =  `${GATEWAY_URL}user/directpropertylogdetails/`;
export const GET_CMS_DETAIL_URL =  `${GATEWAY_URL}user/cms/`;

